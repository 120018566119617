* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif, Arial, Helvetica, sans-serif;
    background: #fafafa;
    color: #333;
}
#main-header {
    width: 100%;
    height: 60px;
    background: #3E005F;
    font-size: 24px;
    font-weight: normal;
    color: #F1CE00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto Slab', serif;
}

#main-header a {
    padding: 10px;
    color: #F1CE00;
}
.conversation {
    max-width: 960px;
    margin: 0 auto 0;
    padding: 0 30px;
    max-height: 450px;
    overflow: auto;
    padding-bottom: 40px;
}

.conversation-input {
    max-width: 940px;
    margin: 0 auto 0;
    max-height: 450px;
}

.list-items {
    box-shadow: inset 0 0 1em #EBECF3, 0 0 1em #EBECF3;
    background-color: #EBECF3;
}

.plan-name {
    font-size: 20px!important;
}

.plan-price {
    font-size: 36px!important;
}

.list-items li {
    list-style: none !important;
    font-size: 13px;
    padding: 0 !important;
    margin: 8px !important;
    margin-left: 12px !important;
}

ul.list-items {
    height: 300px;
    overflow: auto;
}

@media only screen and (max-width: 360px) {
    .conversation {
        max-height: 320px;
    }
}

@media only screen and (max-width: 1024px) {
    .conversation {
        max-height: 600px;
    }
}

.conversa-joy {
    font-size: 18px;
}

.conversation li {
    list-style: none;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    clear: both;
}

.conversation li.joy {
    float: left;
}

.conversation li.user {
    float: right;
    background: #EBECF3;
    box-shadow: 1px 1px 2px #ccc;
}

#loading {
    min-height: 60px;
    background: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 25px;
    font-size: 12px;
    color: #333;
}

.fileContainer .chooseFileButton {
    padding: 0 !important;
}
#inputs {
    min-height: 60px;
    background: #EBECF3;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 25px;
}
.input-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 24px;
    
}
#form-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input-container input {
    padding: 11px 5px;
    border: 0;
    outline: 0;
    width: 95%;
    color: #2E2D33;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0;
    font-weight: 400;
    transition: .2s cubic-bezier(.55,0,.1,1);
    margin-top: 0;
    margin-bottom: 0;
    background-color: inherit;
}
.wrn-button {
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: #3E005F;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    letter-spacing: .03125rem;
    border-radius: 100px;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
            user-select: none;
    min-width: 150px;
    outline: 0;
    border: 0;
    min-height: 55px;
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    margin: 0 5px;
    cursor: pointer;
}

.invalid-button {
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: #666666;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    letter-spacing: .03125rem;
    border-radius: 100px;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
            user-select: none;
    min-width: 150px;
    outline: 0;
    border: 0;
    min-height: 55px;
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    margin: 0 5px;
    cursor: pointer;
}

@media only screen and (max-width: 460px) {
    #inputs {
        min-height: 40px;
    }
    .input-container input {
        padding: 5px 2px;
        border: 0;
        outline: 0;
        width: 95%;
        color: #2E2D33;
        font-size: 18px;
        line-height: 1.5rem;
        letter-spacing: 0;
        font-weight: 400;
        transition: .2s cubic-bezier(.55,0,.1,1);
        margin-top: 0;
        margin-bottom: 0;
        background-color: inherit;
    }
    .wrn-button {
        font-size: 16px;
        border-radius: 100px;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
                user-select: none;
        min-width: 60px;
        outline: 0;
        border: 0;
        min-height: 30px;
        height: 40px;
        line-height: 25px;
        padding: 0 10px;
        cursor: pointer;
    }
}

