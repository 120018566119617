#main-header {
    width: 100%;
    height: 60px;
    background: #3E005F;
    font-size: 24px;
    font-weight: normal;
    color: #F1CE00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto Slab', serif;
}

#main-header a {
    padding: 10px;
    color: #F1CE00;
}