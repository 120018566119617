.conversation {
    max-width: 960px;
    margin: 0 auto 0;
    padding: 0 30px;
    max-height: 450px;
    overflow: auto;
    padding-bottom: 40px;
}

.conversation-input {
    max-width: 940px;
    margin: 0 auto 0;
    max-height: 450px;
}

.list-items {
    box-shadow: inset 0 0 1em #EBECF3, 0 0 1em #EBECF3;
    background-color: #EBECF3;
}

.plan-name {
    font-size: 20px!important;
}

.plan-price {
    font-size: 36px!important;
}

.list-items li {
    list-style: none !important;
    font-size: 13px;
    padding: 0 !important;
    margin: 8px !important;
    margin-left: 12px !important;
}

ul.list-items {
    height: 300px;
    overflow: auto;
}

@media only screen and (max-width: 360px) {
    .conversation {
        max-height: 320px;
    }
}

@media only screen and (max-width: 1024px) {
    .conversation {
        max-height: 600px;
    }
}

.conversa-joy {
    font-size: 18px;
}

.conversation li {
    list-style: none;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    clear: both;
}

.conversation li.joy {
    float: left;
}

.conversation li.user {
    float: right;
    background: #EBECF3;
    box-shadow: 1px 1px 2px #ccc;
}

#loading {
    min-height: 60px;
    background: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 25px;
    font-size: 12px;
    color: #333;
}

.fileContainer .chooseFileButton {
    padding: 0 !important;
}