#inputs {
    min-height: 60px;
    background: #EBECF3;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 25px;
}
.input-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 24px;
    
}
#form-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input-container input {
    padding: 11px 5px;
    border: 0;
    outline: 0;
    width: 95%;
    color: #2E2D33;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0;
    font-weight: 400;
    transition: .2s cubic-bezier(.55,0,.1,1);
    margin-top: 0;
    margin-bottom: 0;
    background-color: inherit;
}
.wrn-button {
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: #3E005F;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    letter-spacing: .03125rem;
    border-radius: 100px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 150px;
    outline: 0;
    border: 0;
    min-height: 55px;
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    margin: 0 5px;
    cursor: pointer;
}

.invalid-button {
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: #666666;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    letter-spacing: .03125rem;
    border-radius: 100px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 150px;
    outline: 0;
    border: 0;
    min-height: 55px;
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    margin: 0 5px;
    cursor: pointer;
}

@media only screen and (max-width: 460px) {
    #inputs {
        min-height: 40px;
    }
    .input-container input {
        padding: 5px 2px;
        border: 0;
        outline: 0;
        width: 95%;
        color: #2E2D33;
        font-size: 18px;
        line-height: 1.5rem;
        letter-spacing: 0;
        font-weight: 400;
        transition: .2s cubic-bezier(.55,0,.1,1);
        margin-top: 0;
        margin-bottom: 0;
        background-color: inherit;
    }
    .wrn-button {
        font-size: 16px;
        border-radius: 100px;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        min-width: 60px;
        outline: 0;
        border: 0;
        min-height: 30px;
        height: 40px;
        line-height: 25px;
        padding: 0 10px;
        cursor: pointer;
    }
}